
import React, {useEffect,useState,useRef } from 'react';

import { useNavigate } from 'react-router-dom'   
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField'; 

import { SetPassword, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next'; 


import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Password() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [ loading, setLoading] = React.useState(false)  
    const [ isDisabled, setIsDisabled] = useState(true)  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        var data = returnData.data;
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        }  
    } 

    const [currentpassword, setCurrentpassword] = useState('');   
    const [password, setPassword] = useState('');   
    const [confirmpassword, setConfirmpassword] = useState('');   
  
    const handleSetPassword = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        let current_password = currentpassword
        let password_confirmation = confirmpassword

        SetPassword({ 
            token,
            current_password,
            password,
            password_confirmation
        }, CallbackSetPassword);
    } 
 
    const CallbackSetPassword = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        var data = returnData.data; 
        if(code === 1)
        { 
            notyf.success(data.result);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    useEffect(() => { 
        setIsDisabled(currentpassword === '' || confirmpassword === '' || password === '');
    }, [ currentpassword, password, confirmpassword]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 

            <div className="page-wrapper home-wrapper profile-wrapper" style={{backgroundImage: "url('/images/main_bg.png')"}}>     
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Password Management')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleSetPassword} >   
                      
                        <div className="form-group">
                            <TextField
                            size="small"
                            id="currentpassword"
                            label={t('Current Password')} 
                            variant="outlined"
                            onChange={(e) => inputChangeHandler(setCurrentpassword, e)}
                            />
                        </div>
                     
                        <div className="form-group">  
                            <TextField size="small" id="password" type="password" label={t('Password')} variant="outlined"  onChange={(e)=>inputChangeHandler(setPassword, e)}  />
                        </div>
                        <div className="form-group">  
                            <TextField size="small" id="passwordconfirm" type="password" label={t('Confirm Password')} variant="outlined"  onChange={(e)=>inputChangeHandler(setConfirmpassword, e)}  />
                        </div> 
                        
                        <div className="form-action">  
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Submit')}</Button> 
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};