
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import '../Common.css'; 
import { Profile, Pages, Banner, PageVersion, BannerVersion } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer'; 
import SliderCarousel from './SliderCarousel';      
import SliderCarouselCenter from './SliderCarouselCenter'; 

import { useTranslation } from 'react-i18next'; 

export default function Home() {  
 
    const { t } = useTranslation();
    const { i18n } = useTranslation(); 
    const navigate = useNavigate(); 
    const { token, setToken } = useToken(); 
    const [commission, setCommission] = useState(0);
    const [credit, setCredit] = useState(0);
    const [todayOrder, setTodayOrder] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);  
    const [ notice, setNotice ] = useState("");  
    const [ isOldBanner, setIsOldBanner ] = useState(false);  
    const [ isOldPage, setIsOldPage ] = useState(false);  
    const [balance, setBalance] = useState(0); 
    const [username, setUsername] = useState(0); 
    const [invitecode, setInvitecode] = useState(0); 
    const [tel, setTel] = useState(0); 

    const handleShop = () => { 
        navigate('/shop');
    }; 

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        
        UserProfile();     
        GetBanner();     
        setNotice("Welcome to Edelman");

        // eslint-disable-next-line
    }, [isOldPage, isOldBanner]); 

    const CheckPageVersion = async e  => { 
        let pageVersion = localStorage.getItem('pageVersion');   
        
        PageVersion({}, CallbackPageVersion);  
    }
    
    const CallbackPageVersion = (returnData) => {  
        
        if(returnData.code === 1)
        { 
            var data = returnData.data;
 
            let pageVersion = localStorage.getItem('pageVersion');  
              
            if(pageVersion !== data.version){ 
                setIsOldPage(true);
                localStorage.setItem('pageVersion', data.version)
            }  
        }   
    } 

    const CheckBannerVersion = async e  => { 
        let bannerVersion = localStorage.getItem('bannerVersion');    
        BannerVersion({}, CallbackBannerVersion);  
 
    }

    const CallbackBannerVersion = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data;
 
            let bannerVersion = localStorage.getItem('bannerVersion');  

            if(bannerVersion !== data.version){ 
                setIsOldBanner(true);
                localStorage.setItem('bannerVersion', data.version)
            }  
        }  
    } 
 
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setToken(data); 
          
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', data.token);  
            localStorage.setItem('userdata', JSON.stringify(data));   
            localStorage.setItem('lang', data.lang);  
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            let mobilecode = data.mobilecode;
            let tel = data.tel; 
            setTel(tel)

            let currentLang = localStorage.getItem('lang');  
            if(data.lang){
                if(currentLang !== data.lang){  
                    localStorage.setItem('lang', data.lang);  
                }
            }
             
            let currentLanguage = i18n.language; 
            if(currentLanguage != currentLang){ 
                i18n.changeLanguage(currentLang);
            }  
            
        } 
    } 
 
    const GetPages = async e => {    
        Pages({
        }, CallbackPages);  
    } 
 
    const [ titleTerms, setTitleTerms ] = useState("");  
    const [ contentTerms, setContentTerms ] = useState("");  
    const [ titleServices, setTitleServices ] = useState("");  
    const [ contentServices, setContentServices ] = useState("");  
    const [ titleFaq, setTitleFaq ] = useState("");  
    const [ contentFaq, setContentFaq ] = useState("");  
    const [ titleCert, setTitleCert ] = useState("");  
    const [ contentCert, setContentCert ] = useState("");  
    const [ titleEvent, setTitleEvent ] = useState("");  
    const [ contentEvent, setContentEvent ] = useState("");  

    const CallbackPages = (returnData) => {  
        if(returnData.code === 1)
        {    
            var data = returnData.data; 
            var pageContent = JSON.stringify(data)
            localStorage.setItem('pageContent', pageContent)
            parsePage(pageContent)
        } 
    } 

    const parsePage = (data) => {
       
        let parsedData = JSON.parse(data);
        
        if(parsedData !== null){
            parsedData.map(item => { 
                if(item.id === 1){
                    setTitleTerms(item.title);
                    setContentTerms(item.content);
                }
                else if(item.id === 2){
                    setTitleServices(item.title);
                    setContentServices(item.content);
                }
                else if(item.id === 3){
                    setTitleFaq(item.title);
                    setContentFaq(item.content);
                }
                else if(item.id === 4){
                    setTitleCert(item.title);
                    setContentCert(item.content);
                }
                else if(item.id === 5){
                    setTitleEvent(item.title);
                    setContentEvent(item.content);
                }
            }); 
        }
 
    }
 
    const shop = () => { 
        navigate('/shop');
    }; 


    
    const [bannerNow, setBannerNow] = useState([]);
    const [bannerSoon, setBannerSoon] = useState([]);  
    const [bannerSlider, setBannerSlider] = useState([]);
    const [bannerOther1, setBannerOther1] = useState([]);
    const [bannerOther2, setBannerOther2] = useState([]);

    const GetBanner = async e => {    
        Banner({
        }, CallbackBanner);  
    } 
    const CallbackBanner = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
             
            setBannerNow(data.now);
            setBannerSoon(data.comingsoon);  
            setBannerSlider(data.banner);  
            setBannerOther1(data.other1);  
            setBannerOther2(data.other2);  
        } 
    } 
 


    return (
    <>
        <div className="page-wrapper home-wrapper landing" style={{backgroundImage: "url('/images/main_bg.png')"}}>   
            <div className="module"> 
                <div className="page-title"> 
                    <h4>{t("Home")}</h4>
                </div>
            </div>
   
            <div className="module"> 
                <div className="movie-category"> 
                    <div className="movie-category-item"> 
                        <img src={"/images/WarFilm.png"} alt={t('WarFilm')}/>
                        <span>{t("Action")}</span>
                    </div> 
                    <div className="movie-category-item"> 
                        <img src={"/images/HorrorMovie.png"} alt={t('HorrorMovie')}/>
                        <span>{t("Horror")}</span>
                    </div> 
                    <div className="movie-category-item"> 
                        <img src={"/images/FantasyMovie.png"} alt={t('FantasyMovie')}/>
                        <span>{t("Fantasy")}</span>
                    </div> 
                </div>
            </div> 

            <div className="module">  
                <h4 onClick={shop}>{t("Now Playing")}<i className="fa-solid fa-chevron-right"></i></h4>
                <SliderCarouselCenter  imageData={bannerNow} /> 
            </div>
            
            <div className="module">  
                <h4 onClick={shop}>{t("Coming Soon")}<i className="fa-solid fa-chevron-right"></i></h4>
                <SliderCarousel  imageData={bannerSoon} />
            </div>

            <div className="module">  
                <div className="ff_wrapper">   
                    <img className="banner" src={"/images/ff_bg.png"} alt={t('Fast and Furious')}/> 
                    <h4 onClick={shop}>{t("Fast and Furious")}<i className="fa-solid fa-chevron-right"></i></h4>
                    <SliderCarousel  imageData={bannerOther1} />
                </div>
            </div>

            <div className="module">  
                <h4 onClick={shop}>{t("Trending Movies")}<i className="fa-solid fa-chevron-right"></i></h4>
                <SliderCarousel  imageData={bannerOther2} />
            </div>

        </div>
        <Footer/>
    </>
    );
};