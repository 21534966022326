import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";  
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField'; 
import LoadingSpinner from '../LoadingSpinner'; 

import { UserRegister, countryCode} from '../../Services/User'
import useToken from '../App/useToken';   
import FormControl from '@mui/material/FormControl'; 

import { Notyf } from "notyf";  
import { useTranslation } from 'react-i18next'; 
 
import { MuiTelInput } from 'mui-tel-input'
 
const notyf = new Notyf({duration: 5000}); 
    
export default function Register() {    
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const { inviteCode } = useParams();
    const [invite_code, setInvite_code] = useState(inviteCode || '');
    const isReadOnly = inviteCode !== undefined; 
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [username, setUsername] = useState('');   
    const [tel, setTel] = useState('');    
    const [mobilecode, setMobilecode] = useState('');   
    const [mobile, setMobile] = useState('');  
    const [pwd, setPwd] = useState('');   
    const [pwdConfirm, setPwdConfirm] = useState('');    
      
    const login = () => { 
        navigate('/login');
    }; 

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        }  
        GetCountryCode(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code === 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data);  
        }  
    } 
      
    const handleRegister = async e => { 
        e.preventDefault(); 
 
        if(pwd !==  pwdConfirm)
        {
            notyf.error(t("Invalid password confirmation")); 
            return false;
        }
        e.preventDefault();      
        setLoading(true); 
        UserRegister({
            username,
            tel,
            pwd,
            pwdConfirm,
            invite_code
        }, Callback);
    } 
 
    const Callback = (returnData) => {    
        setLoading(false);
        var code = returnData.code; 
        if(code === 1)
        { 
            var token = returnData.data;
            setToken(token.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', token.token);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
       
    const handlePhoneChange = (newPhone, info) => { 
        setTel(newPhone) 
        setMobile(info.nationalNumber) 
        setMobilecode(info.countryCallingCode);
    }

    useEffect(() => {
        setIsDisabled(username === '' || tel === '' || pwd === '' || pwdConfirm === '' || invite_code === ''); 
    }, [username, pwd, tel, pwdConfirm, invite_code]);
  
    return( 
        <div className="page-wrapper register-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
           <div className="inner-top-nav">
                <span className="btn-back" onClick={login}><i className="fa-solid fa-arrow-left"></i></span>
                <span className="nav-title">{t('User registration')}</span>
                <span></span>
            </div>
 
 
            <div className="form-info">   
                <form className="loginBox" onSubmit={handleRegister} >   
                 
                    <div className="form-group">  
                        <FormControl variant="standard">  
                            <TextField 
                            id="username" 
                            label={t('Username')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setUsername, e)}  
                            />
                        </FormControl> 
                    </div>
                    
                    <div className="form-group">   
                        <MuiTelInput
                            required
                            variant="standard"
                            defaultCountry={'US'} // Set the default country (optional)
                            label={t("Phone number & Country")}
                            value={tel}
                            onChange={handlePhoneChange}
                            onlyCountries={allowedCountries} 
                        />  
                    </div>
 
                    <div className="form-group">  
                        <FormControl variant="standard">  
                            <TextField 
                            id="password" 
                            label={t('Password')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setPwd, e)} 
                            type="password"/>
                        </FormControl> 
                    </div>
                    <div className="form-group">  
                        <FormControl variant="standard">  
                            <TextField 
                            id="passwordConfirm" 
                            label={t('Password Confirmation')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setPwdConfirm, e)}  
                            type="password"  
                            />
                        </FormControl> 
                    </div>
                    <div className="form-group">  
                        <FormControl variant="standard">  
                            <TextField 
                            id="referralCode" 
                            label={t('Referral Code')} 
                            value={invite_code} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}  
                            onChange={(e)=>inputChangeHandler(setInvite_code, e)}  
                            readOnly={isReadOnly}
                            disabled={isReadOnly}/>
                        </FormControl> 
                    </div>
               
     
                    <div className="form-action">  
                        <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Register')}</Button> 
                    </div>   
                </form> 
            </div> 
        </div>
        
    );
} 