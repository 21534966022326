
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import { PieChart } from '@mui/x-charts/PieChart'; 
 
import { RotOrder, TradeOrder, PostOrderDetail } from '../../Services/User';  
import useToken from '../App/useToken'; 
import LoadingSpinner from '../LoadingSpinner'; 
import Alert from '@mui/material/Alert'; 

import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 
 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
const StyledText = styled('text')(({ theme }) => ({
    fill: "#fff",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 31,
    fontWeight: 'bold',
  }));
  
const StyledText2 = styled('text')(({ theme }) => ({
    fill: "#fff",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 11,
    width:'100px'
  }));
  
function PieCenterLabel({ children }) { 
    const { t } = useTranslation();
    
    return (<>
      <StyledText x={'50%'} y={'105px'}>
       {children}
      </StyledText>
      <StyledText2 x={'165px'} y={'130px'}>
       {t("Remaining Order")}
      </StyledText2>
    </>
    );
  }
export default function Shop() {  
 
    const { tier } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [vipName, setVipName] = useState(""); 
    const [amountFrozen, setAmountFrozen] = useState(0);
    const [todayOrder, setTodayOrder] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [accountBalance, setAccountBalance] = useState(0);
    const [commissionYesterday, setCommissionYesterday] = useState(0); 
    const [commission, setCommission] = useState(0); 
    const [pendingOrder, setPendingOrder] = useState(0); 
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [countdown, setCountdown] = useState(3); 
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orderInfo, setOrderInfo] = useState(false);
    const [orderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [postorderBtn, setPostorderBtn] = useState(false);
    const [userData, setUserData] = useState([]); 

    const [cardNum, setCardNum] = useState("");
    const [userTotal, setUserTotal] = useState(0);
    const [remainOrder, setRemainOrder] = useState(0);
    const [accountBalanceText, setAccountBalanceText] = useState("");

    
    const [tierId, setTierId] = useState(0);
    const [tierTitle, setTierTitle] = useState('');
    const [tierType, setTierType] = useState('vip');
    const [dailyMax, setDailyMax] = useState(0);
    const [roundMax, setRoundMax] = useState(0);
    const [todayLuckCount, setTodayLuckCount] = useState(0);
    const [roundCurrent, setRoundCurrent] = useState(0);
    const [errorType, setErrorType] = useState(0);
    const [tobalance, setTobalance] = useState(false);
    const [luckyRecharge, setLuckyRecharge] = useState(0);
    const [luckyError, setLuckyError] = useState("");
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetRotOrder();   
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    const GetRotOrder = async e => {    
        let token = localStorage.getItem('token');  

        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let cid = tier
            RotOrder({
                token,
                cid,
            }, CallbackRotOrder);  
        }
    } 
    
    const CallbackRotOrder = (returnData) => {   
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setVipName(data.level_name);   
            setTodayOrder(parseFloat(data.user_purchases).toFixed(2));  
            setTotalOrder(data.order_num);  
            setCommissionYesterday(parseFloat(data.yes_user_yongjin).toFixed(2));  
            setCommission(parseFloat(data.day_deal).toFixed(2)); 
            setCardNum(data.card_num); 
            setAmountFrozen(parseFloat(data.lock_deal).toFixed(2));  
            setPendingOrder(parseFloat(data.cash_gap_between_task).toFixed(2));
            setLuckyRecharge(parseFloat(data.lucky_recharge).toFixed(2));
             
            let currentBalance = data.account_balance
            setAccountBalance(parseFloat(currentBalance).toFixed(2));  
            if(data.lucky_id >= 5){ 
                setAccountBalanceText(data.lucky_title);   
                setTobalance(true);   
            }

            if(data.account_balance > 0){
                setIsDisabled(false);
            }
            
            if(data.can_trade === false){
                setIsDisabled(true);
                setError(true);
                setErrorMessage(data.trade_error); 
                setErrorType(data.error_type); 
                if(data.lucky_error){
                    setLuckyError(data.lucky_error); 
                }
            }
            if(data.type !== "vip"){
                setTierId(data.lucky_id);  
                setTierTitle(data.lucky_title);  
                setTierType(data.type);  
                setDailyMax(data.lucky_daily_max);  
                setRoundMax(data.lucky_round_max);  

                setTodayLuckCount(data.day_d_count);  
                setRoundCurrent(data.roundCurrent);  
            }

            let total = parseFloat(data.user_purchases) + parseFloat(data.lock_deal) + parseFloat(data.day_deal) + parseFloat(data.yes_user_yongjin) + parseFloat(Math.abs(data.cash_gap_between_task)); 

            total = total.toFixed(2);

            let id0 = (data.user_purchases)
            let id1 = (data.lock_deal )
            let id2 = (data.day_deal )
            let id3 = (data.yes_user_yongjin )
            let id4 = (Math.abs(data.cash_gap_between_task) )

            let userSummary = [
                { id: 0, value: parseFloat(id0).toFixed(2), color: '#3b4acb', label: 'Purchased today' },
                { id: 1, value: parseFloat(id1).toFixed(2), color: '#E7854D',label: 'Amount frozen money' },
                { id: 2, value: parseFloat(id2).toFixed(2), color: '#C65468',label: 'Commission today' },
                { id: 4, value: parseFloat(id3).toFixed(2), color: '#FFC700',label: 'Purchased commission yesterday' },
                { id: 5, value: parseFloat(id4).toFixed(2), color: '#5DD597',label: 'Cash gap between task' },
                { id: 6, value: parseFloat(remainOrder), color: '#e7c907',label: 'Remain Order' },
            ]; 
            setUserTotal(total);
             
            const numericData = userSummary.map(item => ({
                ...item,
                value: parseFloat(item.value),
            })); 
            setUserData(numericData); 

            let remaining = data.order_num - data.day_d_count;
            if(remaining <= 0){
                remaining = 0;
                setIsDisabled(true);
            }
            setRemainOrder(remaining);
              
        } 
    } 
 
    const smartBuyer = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            setIsDisabled(true);
            setLoading(true); 
            for (let i = countdown; i >= 1; i--) {
              setCountdown(i);
              await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
            }
             
            try { 
                setLoading2(true);
                let cid = tier
                await TradeOrder({
                    token,
                    cid
                }, CallbackSmartBuyer);   
            } catch (error) {
                notyf.error(t("Error submitting order"));  
            } finally {
                setLoading(false);
                setCountdown(3);
            }
 
        }
    } 
    
    const CallbackSmartBuyer = (returnData) => {   
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{ 
                setLoading2(false);
                setError(true);
                setErrorMessage(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            getOrderInfo(lists.oid)
        } 
    } 
 
    
    const [status, setStatus] = useState('idle'); // idle, submitting, allocated, waiting
    const [countdown2, setCountdown2] = useState(0);
    const [loading3, setLoading3] = useState(false);

    const countdownDelay = async () => {
        for (let i = 3; i >= 1; i--) {
            setCountdown2(i);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }
    };

    const postOrder = async (order_number) => {    
        
        setPostorderBtn(true)
        setOrderError(false)
        setOrderErrorMessage("")

        setLoading3(true);
        setStatus(t('submitting...'));
        setCountdown2(2);

        // Countdown before the next step
        await countdownDelay();
        
        setStatus(t('The remote host is being allocated...'));
        setCountdown2(3);

        // Countdown before the next step
        await countdownDelay();

        setStatus(t('Waiting for the merchant system to respond'));
        setCountdown2(2);

        // Countdown before sending the API request
        await countdownDelay();
        
        try {
            // Call your sendapi function here
            let tokenInfo = localStorage.getItem('token');  
            if(tokenInfo === null){ 
                navigate('/login');
            } 
            else {    
                setLoading3(false);
                setLoading2(true);
                let token = tokenInfo; 
                let oid   = order_number; 
                await PostOrderDetail({
                    oid,
                    token
                }, CallbackPostOrder);  
            }
        } catch (error) {
            console.error(error); // Handle the error
        }
    
        // Reset status and countdown after completing the process
        setStatus('');
        setCountdown2(0);  
    } 
    
    const CallbackPostOrder = (returnData) => {  
        setLoading2(false);
        setLoading3(false);
        setPostorderBtn(false);

        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{
                setOrderError(true);
                setOrderErrorMessage(returnData.message);
                notyf.error(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            setOrderInfo(false);
            setOrderDetail([]);
            notyf.success(t("success")); 
            GetRotOrder(); 
        } 
    } 

    const getOrderInfo = (order_number) => {     
        navigate('/order/'+order_number); 
    } 
     
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleClickConfirm = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleCloseConfirm2 = () => {
        setOpenConfirm(false);
        
        setOrderInfo(false);
        setOrderDetail([]);
        GetRotOrder(); 
    };

    const record = () => { 
        navigate('/record');
    }; 
    
    const handleDeposit = (e) => { 
        e.preventDefault();
        navigate('/deposit');
        // navigate('/deposits/trc20');
    }; 
    
    const handleConvert = (e) => { 
        e.preventDefault();
        navigate('/convert');
        // navigate('/deposits/trc20');
    }; 

    return (
        <>
        <div className="page-wrapper home-wrapper shop-wrapper" style={{backgroundImage: "url('/images/main_bg.png')"}}>   
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t("Reserve")}</span>
                    <span></span>
                </div>
 
                <div className="module app_wrapper"> 
                    <div className="accoun-balance"  style={{backgroundImage: "url('/images/bg_shop_balance.png')"}}> 
                        <div className="account-balance-info"> 
                            <div className="account-balance-header"> 
                                <h4>{t("Total Balance")}{accountBalanceText !== "" && (
                                    <span> ({accountBalanceText})</span>
                                )}</h4>
                            </div> 
                        </div> 
                        <div className="account-balance-info"> 
                            <div className="total_balance">
                                ${accountBalance} 
                            {tobalance && (
                                <span className="toBalance" onClick={handleConvert}>({t("withdraw")})</span>
                            )}
                            </div> 
                        </div>
                    </div>

                    <div className="account-summary"> 
                        <div className="account-summary-info">  
                            <h4>{t("Recent Activity")}</h4>
                            <a className="more-link" onClick={record} href="#">{t("See more")}<i className="fa-solid fa-arrow-right"></i></a>
                        </div> 
 
                        <div className="account-chart"> 
                            <img src="/images/bg_chart.png" className="bg"/>
                            <PieChart
                                series={[
                                    {
                                        data: userData,
                                        paddingAngle: 2,
                                        innerRadius: 50,
                                        cornerRadius: 5,
                                        cx: 160,
                                        cy: 110,
                                    },
                                ]}
                                slotProps={{
                                    legend: { hidden: true }, 
                                }}
                                width={320}
                                height={260}
                                > 
                                    <PieCenterLabel>{remainOrder}</PieCenterLabel>  
                            </PieChart>
                        </div>

                        <div className="account-summary-lists">  
                            <div className="order-list-items"> 
                                <div className="order-list-item-title">{t('Total transaction amount')}</div>
                                <div className="order-list-item-value">{todayOrder}</div>
                            </div>
                            <div className="order-list-items"> 
                                <div className="order-list-item-title">{t('Amount frozen money')}</div>
                                <div className="order-list-item-value">{amountFrozen}</div>
                            </div>
                            <div className="order-list-items"> 
                                <div className="order-list-item-title">{t('Commission today')}</div>
                                <div className="order-list-item-value">{commission}</div>
                            </div>
                            <div className="order-list-items"> 
                                <div className="order-list-item-title">{t('Purchased commission yesterday')}</div>
                                <div className="order-list-item-value">{commissionYesterday}</div>
                            </div>
                            <div className="order-list-items"> 
                                <div className="order-list-item-title">{t('Cash gap between task')}</div>
                                <div className="order-list-item-value">{pendingOrder}</div>
                            </div>
                        </div> 
                    </div>
 
                        {tierId >= 5 && ( 
                                <>
                                <div className="account-summary"> 
                                    <div className="account-summary-info">  
                                        <h4>{tierTitle}</h4> 
                                    </div>
                                    <div className="account-summary-lists">   
                                        <div className="order-list-items" style={{color:"#1876ea"}}>  
                                            <div className="order-list-item-title">{t('Total Recharge')}</div>
                                            <div className="order-list-item-value">{luckyRecharge}</div>
                                        </div>
                                        <div className="order-list-items" style={{color:"#333ca5"}}>  
                                            <div className="order-list-item-title">{t('Max Daily Task')}</div>
                                            <div className="order-list-item-value">{dailyMax}</div>
                                        </div>
                                        <div className="order-list-items" style={{color:"#1876ea"}}>  
                                            <div className="order-list-item-title">{t('Total Task Count')}</div>
                                            <div className="order-list-item-value">{todayLuckCount}</div>
                                        </div>
                                        {tierId === 5 && ( 
                                            <>
                                            <div className="order-list-items" style={{color:"#333ca5"}}>  
                                                <div className="order-list-item-title">{t('Max Round')}</div>
                                                <div className="order-list-item-value">{roundMax}</div>
                                            </div>
                                            <div className="order-list-items" style={{color:"#1876ea"}}>  
                                                <div className="order-list-item-title">{t('Current Round')}</div>
                                                <div className="order-list-item-value">{roundCurrent}</div>
                                            </div>
                                            </>
                                        )}
                                    </div> 
                                </div> 
                                </>
                            )} 


                    
                    <div className="order-action">  
                        <button className="btn btn-smart" onClick={smartBuyer} disabled={isDisabled}  variant="contained">{t('Smart Buyers')}</button> 
                    </div> 

                </div>
            </div>
            {errorMessage && ( 
                <Alert severity="error" className="mt-3">
                    {errorMessage}
                </Alert> 
            )}
            {luckyError && ( 
                <BootstrapDialog 
                aria-labelledby="customized-dialog-title"
                    open={true}
                > 
                    <DialogContent dividers>
                        <>
                        {luckyError}
                        </>
                    </DialogContent> 
                    <DialogActions>
                        {errorType === 1 && (  
                            <Button size="sm" variant="contained" color="success" onClick={handleDeposit}>{t('Recharge')}</Button>  
                        )}
                        <Button size="sm" variant="contained" onClick={handleGoBack}>{t('Back')}</Button> 
                    </DialogActions>
                </BootstrapDialog>
            )}


            {loading ? (
                
                <div className="loading">
                    <div className="loading-text">
                    <p>{t('Matched')}...{countdown}</p> 
                    </div>
                </div>
                
            ) :  '' }

            {loading2 ? <LoadingSpinner /> : ''}

            {loading3 ? (
                
                <div className="loading">
                    <div className="loading-text">
                        <div>
                        {status} 
                        </div>
                    </div>
                </div>
                
            ) :  '' }
  
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            > 
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {t('Are you sure to cancel？Cancellation will freeze the Amount')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseConfirm}>{t('Cancel')}</Button>
                <Button onClick={handleCloseConfirm2} autoFocus>
                {t('Confirm')}
                </Button>
                </DialogActions>
            </Dialog>

            
            <BootstrapDialog
                onClose={handleCloseConfirm}
                aria-labelledby="customized-dialog-title"
                open={orderInfo}
                >
                <DialogTitle className="text-center"> 
                {t("Connection successful")}
                </DialogTitle> 
                <DialogContent dividers> 
                <>
                    <OrderdetailComponent item={orderDetail} /> 
                    {orderError ?  
                    <Alert severity="error" className="mt-3 mb-3">{orderErrorMessage}</Alert> 
                    : ''} 
                </>         
                </DialogContent>
                <DialogActions>
                    
                    <Button className="btn btn-primary" onClick={() => postOrder(orderDetail.orderNumber)} disabled={postorderBtn} size="sm" type="submit"  variant="contained">{t('Submit')}</Button> 
                    <Button className="btn btn-danger" size="sm" variant="outlined" onClick={handleClickConfirm}>{t('Cancel')}</Button>
 
                </DialogActions>
            </BootstrapDialog>

        </>
    );
};