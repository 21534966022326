
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import { DepositHistory } from '../../Services/User';   
import DepositComponent from './DepositComponent';  
import { useTranslation } from 'react-i18next'; 
import { Notyf } from "notyf";

const notyf = new Notyf({duration: 5000}); 
 
export default function RechargeLogs() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [iswaiting, setIswaiting] = useState(false); 
    const [iscomplete, setIscomplete] = useState(false); 
    const [list, setList] = useState([]); 
    const [depositData, setDepositData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        getDepositHistory();
        // eslint-disable-next-line
    }, [currentPage]); 
 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    const getDepositHistory = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            setIswaiting(true);
            setIscomplete(false);
            setList([]); 
            let token   = tokenInfo; 
            let page    = currentPage; 
            DepositHistory({
                page,
                token
            }, CallbackDepositHistory);  
        }
    } 
    
    const CallbackDepositHistory = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;   
            setDepositData(lists.data);
            setCurrentPage(lists.current_page);
            setTotalPages(lists.last_page); 
        } 
    } 
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
    };

    return (
    <>
        <div className="page-wrapper home-wrapper profile-wrapper  record-wrapper" style={{backgroundImage: "url('/images/main_bg.png')"}}>
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span>{t('Deposit History')}</span>
                <span></span>
            </div>
  

            <div className="record-lists">   
            {depositData.length > 0 ? (
                <>
                {depositData.map((item, index) => (
                    <DepositComponent key={index} item={item}/>
                ))} 
                <div className="paginator">
                    <span
                        className={`paginator-link ${currentPage === 1 ? 'disabled' : ''}`}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </span>
                    <span>{t('Page')} {currentPage} / {totalPages}</span>
                    <span 
                        className={`paginator-link ${currentPage === totalPages ? 'disabled' : ''}`} 
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </span>
                </div>
                </>
                
            ) : (
                <div className="text-center">{t('No record available')}</div>
            )}
            </div>  
        </div> 
    </>
    );
};