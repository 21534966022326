
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
 
import { RotOrder, TradeOrder, PostOrderDetail, GetOrderDetail } from '../../Services/User';  
import useToken from '../App/useToken'; 
import LoadingSpinner from '../LoadingSpinner'; 
import Alert from '@mui/material/Alert'; 

import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 


import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
export default function OrderInfo() {  
 
    const { orderNumber } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [vipName, setVipName] = useState(""); 
    const [amountFrozen, setAmountFrozen] = useState(0);
    const [todayOrder, setTodayOrder] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [accountBalance, setAccountBalance] = useState(0);
    const [commissionYesterday, setCommissionYesterday] = useState(0); 
    const [commission, setCommission] = useState(0); 
    const [pendingOrder, setPendingOrder] = useState(0); 
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orderInfo, setOrderInfo] = useState(false);
    const [orderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [postorderBtn, setPostorderBtn] = useState(false);

    const [cardNum, setCardNum] = useState("");


    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        getOrderInfo(orderNumber);  
        
        // setLoading2(true);
        // setLoading(true);
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
      
    const [status, setStatus] = useState('idle'); // idle, submitting, allocated, waiting
    const [countdown2, setCountdown2] = useState(0);
    const [loading3, setLoading3] = useState(false);

    const countdownDelay = async () => {
        for (let i = 3; i >= 1; i--) {
            setCountdown2(i);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }
    };

    const postOrder = async (order_number) => {    
        
        setPostorderBtn(true)
        setOrderError(false)
        setOrderErrorMessage("")

        setLoading3(true);
        setStatus(t('submitting...'));
        setCountdown2(2);

        // Countdown before the next step
        await countdownDelay();
        
        setStatus(t('The remote host is being allocated...'));
        setCountdown2(3);

        // Countdown before the next step
        await countdownDelay();

        setStatus(t('Waiting for the merchant system to respond'));
        setCountdown2(2);

        // Countdown before sending the API request
        await countdownDelay();
        
        try {
            // Call your sendapi function here
            let tokenInfo = localStorage.getItem('token');  
            if(tokenInfo === null){ 
                navigate('/login');
            } 
            else {    
                setLoading3(false);
                setLoading2(true);
                let token = tokenInfo; 
                let oid   = order_number; 
                await PostOrderDetail({
                    oid,
                    token
                }, CallbackPostOrder);  
            }
        } catch (error) {
            console.error(error); // Handle the error
        }
    
        // Reset status and countdown after completing the process
        setStatus('');
        setCountdown2(0);  
    } 
    
    const CallbackPostOrder = (returnData) => {  
        setLoading2(false);
        setLoading3(false);
        setPostorderBtn(false);

        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{
                setOrderError(true);
                setOrderErrorMessage(returnData.message);
                notyf.error(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            setOrderInfo(false);
            setOrderDetail([]);
            notyf.success(t("success"));  
            window.history.back(); // Navigate back to the previous page
        } 
    } 

    const getOrderInfo = (order_number) => {    
   
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            let token = tokenInfo; 
            let oid   = order_number; 
            GetOrderDetail({
                oid,
                token
            }, CallbackOrderInfo);  
        }
    } 
    
    const CallbackOrderInfo = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else
            { 
                notyf.error(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            setLoading2(false);
            setOrderInfo(true);
            setOrderDetail(lists);
        } 
    } 
 
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleClickConfirm = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm2 = () => {
        setOpenConfirm(false);
        
        setOrderInfo(false);
        window.history.back(); // Navigate back to the previous page
    };

    const record = () => { 
        navigate('/record');
    }; 
    return (
        <>
            {orderDetail ? ( 
                <div className="page-wrapper shop-wrapper">  
                    <div className="inner-top-nav">
                        <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                        <span>{t("Submit Payment")}</span>
                        <span></span>
                    </div> 
                    <div className="module app_wrapper">  
                        <div className="order-summary">
                            <h1>{t("Payment Details")}</h1> 
                            <OrderdetailComponent item={orderDetail} /> 
                            {orderError ?  
                            <Alert severity="error" className="mt-3 mb-3">{orderErrorMessage}</Alert> 
                            : ''} 

                            {orderDetail.status == 0 ?
                                <div className="order-summary-action">
                                    <button className="btn btn-smart" onClick={() => postOrder(orderDetail.orderNumber)} disabled={postorderBtn} type="submit">{t('Submit')}</button> 
                                    <button className="btn btn-cancel"onClick={handleClickConfirm}>{t('Cancel')}</button>
                                </div>
                            
                            :''}
                        </div>
                        
                    </div>
                </div>
                
            ) : ''}
            
            {error ?  
                <Alert severity="error" className="mt-3">{errorMessage}</Alert> 
            : ''} 
            {loading ? (
                
                <div className="loading">
                    <div className="loading-text">
                    <p>{t('Matched')}...{countdown}</p> 
                    </div>
                </div>
                
            ) :  '' }

            {loading2 ? <LoadingSpinner /> : ''}

            {loading3 ? (
                
                <div className="loading">
                    <div className="loading-text">
                        <div>
                        {status} 
                        </div>
                    </div>
                </div>
                
            ) :  '' }
  
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            > 
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {t('Are you sure to cancel？Cancellation will freeze the Amount')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseConfirm}>{t('Cancel')}</Button>
                <Button onClick={handleCloseConfirm2} autoFocus>
                {t('Confirm')}
                </Button>
                </DialogActions>
            </Dialog>

             

        </>
    );
};