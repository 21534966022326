
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import Button from '@mui/material/Button'; 
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { UpdatetLang, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from "react-i18next";
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Language() {  
    const navigate = useNavigate();  
    const { i18n } = useTranslation(); 
    const { t }    = useTranslation();
    
    const [loading, setLoading] = useState(false)  
    const [lang, setLang] = useState('en'); 
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setLang(data.lang);  
        } 
    }  

    const handleUpdateLang = async e => {    
        e.preventDefault();      
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        setLoading(true);
        let token = tokenInfo;
            
        i18n.changeLanguage(lang);
        UpdatetLang({ 
            lang,
            token
        }, CallbackUpdatetLang);
    } 
 
    const CallbackUpdatetLang = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data;
            notyf.success(t(returnData.message));  
            localStorage.setItem('lang', data.lang);  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    const handleChange = (event) => {
        setLang(event.target.value);  
    };
    
    return (
        <>
            {loading ? <LoadingSpinner /> : ''}  
             <div className="page-wrapper home-wrapper account-wrapper" style={{backgroundImage: "url('/images/main_bg.png')"}}>  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Change Language')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleUpdateLang} >   
                        <FormControl>
                            <FormLabel>{t('Language')}</FormLabel>
                            <RadioGroup
                                aria-labelledby="language"
                                name="language"
                                value={lang}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="en" control={<Radio />} label={t('English')} />
                                <FormControlLabel value="ar" control={<Radio />} label={t('Arabic')} />

                                <FormControlLabel value="zh" control={<Radio />} label={t('Traditional Chinese')} />
                                <FormControlLabel value="pt" control={<Radio />} label={t('Portuguese')} />
                                <FormControlLabel value="es" control={<Radio />} label={t('Spanish')} />
                                <FormControlLabel value="fr" control={<Radio />} label={t('French')} />
                                <FormControlLabel value="de" control={<Radio />} label={t('German')} />
                                <FormControlLabel value="th" control={<Radio />} label={t('Thai')} />
                                <FormControlLabel value="hi" control={<Radio />} label={t('Hindi')} />
                                <FormControlLabel value="ru" control={<Radio />} label={t('Russian')} />
                                <FormControlLabel value="bn" control={<Radio />} label={t('Bengali')} />
                                <FormControlLabel value="ja" control={<Radio />} label={t('Japanese')} />
                                <FormControlLabel value="ko" control={<Radio />} label={t('Korean')} /> 
                            </RadioGroup>
                        </FormControl>
                         
                        <div className="form-action">  
                            <Button type="submit" className="btn btn-primary" variant="contained">{t('Submit')}</Button>
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};